<template>
  <div>
    <data-table
      ref="table"
      title="Packages"
      :loader="loadPackages"
      :headers="headers"
      :allow-add="false"
      :edit-handler="null"
      :show-search="false"
      :view-handler="viewHandler"
      :key="dataTableKey"
    >
      <template #primary-action>
        <v-btn
          class="ml-2"
          color="primary"
          elevation="0"
          @click="webCoupenDialog = true"
        >
          Web App Banners
          <v-icon
            class="ml-2 dot-icon"
            :color="
              webAppBanner &&
              webAppBanner.is_active === true &&
              webAppBanner.sale_period > webAppBanner.currentDate
                ? 'yellow'
                : 'red'
            "
            x-small
          >
            mdi-circle
          </v-icon>
        </v-btn>
      </template>
    </data-table>

    <v-dialog width="500" v-model="webCoupenDialog">
      <v-card class="py-2">
        <v-card-title class="mb-4"> Web App Banners</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="webAppBanner.name"
            outlined
            label="Name"
            dense
            hide-details
            :rules="[required()]"
            class="mb-4"
          />

          <v-textarea
            v-model="webAppBanner.description"
            outlined
            label="Description"
            dense
            hide-details
            :rules="[required()]"
            class="mb-4"
          />

          <v-select
            v-model="selectedCoupon"
            outlined
            dense
            label="Coupon"
            clearable
            :items="webCoupens"
            item-text="label"
            class="mb-n2"
          ></v-select>

          <date-time-picker
            v-model="webAppBanner.sale_period"
            timeFormat="hh:mm:ss a"
            label="Sale Period"
            :rules="[required()]"
          ></date-time-picker>

          <v-checkbox
            v-model="webAppBanner.is_active"
            label="Active"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="primary" @click="applyCoupen" elevation="0"
            >Apply</v-btn
          >
          <v-btn color="primary" @click="webCoupenDialog = false" outlined
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loading-dialog v-model="loading" :message="loadingMessage" />
    <error-dialog v-model="error" :error="errorValue" />
  </div>
</template>

<script>
import DataTable from '../../components/DataTable';
import LoadingDialog from '../../components/LoadingDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { getUser } from '@/utils/local';
import { required, requiredPercentage } from '@/utils/validators';
import { WebAppOfferService } from '@/services/web-app-offers-service';
import DateTimePicker from '@/components/settings/DateTimePicker';
const { format, parseISO } = require('date-fns');

export default {
  name: 'PackageView',
  components: {
    ErrorDialog,
    LoadingDialog,
    DataTable,
    DateTimePicker
  },

  data: () => ({
    webCoupenDialog: false,
    webCoupens: [],
    webAppBanner: {},
    dataTableKey: 0,

    packages: [],

    id: '',
    selectedCoupon: '',
    showDialog: false,
    service: new WebAppOfferService(),
    coupons: [],
    headers: [
      {
        value: 'name',
        text: 'Name'
      },
      {
        value: 'description',
        text: 'Description'
      }
    ],
    isEdit: false,
    error: false,
    errorValue: {},
    loading: false,
    loadingMessage: ''
  }),

  methods: {
    getUser,
    required,
    requiredPercentage,

    formateDate(inputDate, outputFormat) {
      const dateObj1 = parseISO(inputDate);
      const formattedDate = format(dateObj1, outputFormat);
      return formattedDate;
    },

    setBannersDetails(response) {
      const webBannerIndex = response.data.sales.findIndex(
        (sale) => sale.platform === 1
      );

      if (webBannerIndex !== -1) {
        const webBanner = response.data.sales[webBannerIndex];
        this.webAppBanner.id = webBanner.id;
        this.webAppBanner.name = webBanner.name;
        this.webAppBanner.description = webBanner.description;
        this.webAppBanner.percentage_off = webBanner.percentage_off;
        this.webAppBanner.is_active = webBanner.is_active;
        this.webAppBanner.createdAt = webBanner.createdAt;
        this.webAppBanner.sale_period = this.formateDate(
          webBanner.sale_period,
          'yyyy-MM-dd hh:mm:ss a'
        );

        this.webAppBanner.currentDate = new Date().toISOString();
        this.webAppBanner.currentDate = this.formateDate(
          this.webAppBanner.currentDate,
          'yyyy-MM-dd hh:mm:ss a'
        );
      }
    },

    async applyCoupen() {
      this.loading = true;
      this.loadingMessage = 'Applying coupen...';
      let coupon = {};

      if (
        this.selectedCoupon === null ||
        this.webAppBanner.is_active === false
      ) {
        coupon = {
          metadata: {
            is_discount: '0',
            coupon: ''
          }
        };
      } else {
        coupon = {
          metadata: {
            is_discount: '1',
            coupon: this.selectedCoupon
          }
        };
      }

      this.packages.map(async (item) => {
        await this.service.applyCouponToPrice(item.id, coupon).catch((err) => {
          this.loading = false;
          console.error(err);
          this.$toast.error('Error while applying coupen to ' + item.name);
        });
      });

      this.webCoupens.filter((item) => {
        if (item.id === this.selectedCoupon) {
          this.webAppBanner.percentage_off = item.id;
        }
      });

      const date = new Date(this.webAppBanner.sale_period);
      const clonedBanner = {
        ...this.webAppBanner,
        sale_period: date.toISOString(),
        platform: 1
      };

      try {
        await this.$axios
          .patch('/sales', clonedBanner)
          .then(() => {
            this.loading = false;
            this.$toast.success('Coupen applied successfully');
            this.webCoupenDialog = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$toast.error('Error while applying coupen');
            console.error(error);
          });
      } catch (error) {
        this.toast('Error occurred while updating Sales Banner');
      }

      await this.$axios
        .get('/sales')
        .then((response) => {
          this.setBannersDetails(response);
        })
        .catch((error) => {
          console.error(error);
        });

      this.refresh();
    },

    async loadPackages() {
      await this.$axios
        .get('/sales')
        .then((response) => {
          this.setBannersDetails(response);
        })
        .catch((error) => {
          console.error(error);
        });

      this.webCoupens = await this.service.fetchAllCoupon();

      this.webCoupens = this.webCoupens.data.map((item) => {
        return {
          label: item.name + ` (${item.percent_off}%)`,
          value: item.id,
          ...item
        };
      });

      this.webCoupens.map((item) => {
        if (item.id === this.webAppBanner.percentage_off) {
          this.selectedCoupon = item.value;
        }
      });

      let packages = await this.service.fetchAllPackages();
      this.packages = packages.data;

      return (await this.service.fetchAllPackages()).data;
    },

    refresh() {
      let btns = document.getElementsByClassName('refresh');
      for (let i = 0; i < btns.length; i++) {
        btns[i].click();
      }
    },

    viewHandler(item) {
      this.$router.push({ name: 'PricesView', params: { id: item.id } });
    }
  }
};
</script>
